<template>
	<div class="flex flex-column justify-between h-full">
		<div>
			<div
				class="w-full flex py-4 px-5"
				:class="activeSubMenu ? 'justify-between' : 'justify-end'"
			>
				<div
					v-if="activeSubMenu"
					class="flex cursor-pointer"
					@click="activeSubMenu = null"
				>
					<Icon name="md-chevron_left" />
					<div>{{ $t("components.navigations.label.back") }}</div>
				</div>
				<Icon
					name="md-close"
					class="cursor-pointer"
					@click="emit('closeDrawer')"
				/>
			</div>
			<template v-if="!activeSubMenu">
				<div class="text-xl font-bold mb-4 px-5">{{ $t("components.nav.menu") }}</div>
				<div class="divider"></div>
				<NuxtLink
					v-for="route in routes"
					:key="route.name"
					:to="route.url"
				>
					<template v-if="!route.subMenu">
						<div
							class="flex px-5 py-3 gap-3"
							@click="route.actionOnClick"
						>
							<Icon :name="route.icon" />
							<div>{{ $t(route.name) }}</div>
						</div>
						<div class="divider"></div>
					</template>
					<template v-else-if="route.subMenu?.length">
						<div
							class="flex justify-between px-5 py-3 cursor-pointer"
							@click="activeSubMenu = route.name"
						>
							<div class="flex gap-3">
								<Icon :name="route.icon" />
								<div>{{ $t(route.name) }}</div>
							</div>
							<Icon name="md-chevron_right" />
						</div>
						<div class="divider"></div>
					</template>
				</NuxtLink>
				<NuxtLink
					v-if="isLoggedIn"
					to="/orders"
				>
					<div class="flex px-5 py-3 gap-3">
						<Icon name="md-package" />
						<div>{{ $t("components.nav.orders") }}</div>
					</div>
				</NuxtLink>
			</template>
			<template v-else>
				<div class="text-xl font-bold mb-4 px-5">{{ $t(activeSubMenu) }}</div>
				<div class="divider"></div>
				<NuxtLink
					v-for="(subRoute, index) in routes.find(route => route.name === activeSubMenu)?.subMenu"
					:key="index"
					:to="subRoute.url"
					@click="emit('closeDrawer')"
				>
					<div
						class="flex px-5 py-3 gap-3"
						:class="subRoute.isBold ? 'font-bold' : ''"
					>
						<div>{{ $t(subRoute.label) }}</div>
					</div>
				</NuxtLink>
			</template>
		</div>
		<div>
			<div class="divider"></div>
			<div
				v-if="isLoggedIn"
				class="flex px-5 py-3 gap-3 cursor-pointer"
				@click="signOut"
			>
				<Icon name="md-logout" />
				<div>{{ $t("components.nav.logout") }}</div>
			</div>
			<template v-else>
				<NuxtLink
					to="/auth"
					class="inline-block px-5 py-3 cursor-pointer"
				>
					<div>{{ $t("components.nav.login") }}</div>
				</NuxtLink>
				<div class="divider"></div>
				<NuxtLink
					to="/auth?tab=sign_up&accountType=business"
					class="inline-block px-5 py-3 cursor-pointer"
				>
					<div>{{ $t("components.nav.create_account") }}</div>
				</NuxtLink>
			</template>
			<div class="divider"></div>
			<!-- <div
				v-if="isLoggedIn"
				class="flex px-5 py-3 gap-3"
			>
				<Icon name="md-account_circle" />
				<div>{{ $t("components.nav.my_account") }}</div>
			</div>
			<NuxtLink
				v-else
				to="/sign-in"
				class="flex px-5 py-3 gap-3"
			>
				<Icon name="md-login" />
				<div>{{ $t("components.nav.login") }}</div>
			</NuxtLink>
			<div class="divider"></div> -->
			<!-- Hiding language switcher as there is currently only English -->
			<!-- <div class="flex px-5 py-3 gap-3">
				<Icon name="md-language" />
				<div>{{ $t("general.langs.english") }}</div>
			</div> -->
			<div class="divider"></div>
			<div class="flex items-center justify-center py-6">
				<span class="text-[12px] mr-1">{{ $t("general.powered_by") }}</span>
				<TengivaLogo
					color="black"
					class="w-[118px] h-[24px] mt-[4px]"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import TengivaLogo from "@/components/TengivaLogo.vue";
import type { MenuItem } from "@/types/general";

interface Props {
	isMobileDrawerOpen: boolean;
	collections: MenuItem[];
}
const props = defineProps<Props>();

const platformStore = usePlatformStore();
const { platform } = storeToRefs(platformStore);

const catalogStore = useCatalogStore();
const { setProducts } = catalogStore;

const { isLoggedIn } = storeToRefs(useUserStore());
const { userLogout } = useUserStore();
const router = useRouter();

const emit = defineEmits<{
	(e: "closeDrawer"): void;
}>();

const handleTextileLinkClick = () => {
	if (router.currentRoute?.value.name === "textiles") {
		setFilters({});
		setOrder("");
		setProducts(platform.value?.platform_settings?.[0]?.id);
	}
};

const activeSubMenu = ref<string | null>(null);

watch(
	() => props.isMobileDrawerOpen,
	newVal => {
		if (newVal) {
			activeSubMenu.value = null;
		}
	}
);

const routes = computed(() => [
	{
		name: "components.nav.products",
		url: "/textiles",
		icon: "md-menu",
		actionOnClick: handleTextileLinkClick,
	},
	{
		name: "components.nav.collections",
		icon: "md-grid_view",
		subMenu: props.collections,
	},
	{
		name: "components.nav.merchants",
		url: "/merchants",
		icon: "md-store",
	},
	// {
	// 	name: "components.nav.blog",
	// 	url: "/blog",
	// 	icon: "md-menu",
	// },
	// {
	// 	name: "components.nav.about_us",
	// 	url: "/about-us",
	// 	icon: "md-info",
	// },
	// {
	// 	name: "components.nav.contact",
	// 	url: "/contact-us",
	// 	icons: "md-mail",
	// },
]);

async function signOut() {
	await userLogout();
	router.push("/");
	emit("closeDrawer");
}
</script>
