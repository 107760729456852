<template>
	<div
		class="h-[30px] w-full z-10 bg-neutral-50 fixed top-0 hidden md:flex justify-between items-center page-px text-[14px]"
	>
		<div v-if="props.isEditorial">
			<!-- Hiding language switcher as there is currently only English -->
			<!-- {{ $t("general.langs.english") }} -->
		</div>
		<!-- <button
			v-if="props.isEditorial"
			class="flex items-center"
		>
			{{ $t("general.langs.english") }}
			<Icon
				name="md-expand_more"
				class="mt-[3px]"
			/>
		</button> -->

		<div :class="props.isEditorial ? 'absolute left-1/2 -translate-x-1/2' : ''">
			{{ props.customMsg }}
		</div>
		<div class="flex items-center gap-4">
			<NuxtLink
				v-if="about_page_published"
				to="/about-us"
				class="text-gray-500 cursor-pointer"
				>{{ $t("components.nav.about_us") }}</NuxtLink
			>
			<NuxtLink
				to="/contact-us"
				class="text-gray-500 cursor-pointer"
				>{{ $t("components.nav.contact") }}</NuxtLink
			>
			<!-- <div v-if="props?.platformStyle !== 'Editorial'">{{ $t("general.langs.english") }}</div> -->

			<!-- <button
				v-if="!props.isEditorial"
				class="flex items-center"
			>
				{{ $t("general.langs.english") }}
				<Icon
					name="md-expand_more"
					class="mt-[3px]"
				/>
			</button> -->
		</div>
	</div>
</template>
<script lang="ts" setup>
// import Icon from "../Icon/index.vue";

const { platform } = storeToRefs(usePlatformStore());

const about_page_published = computed(() => {
	return platform.value?.platform_settings[0].pages.filter(p => p.page_name === "About Us")[0].publish_state;
});

interface TopBarProps {
	isEditorial: boolean;
	customMsg?: "string";
}
const props = defineProps<TopBarProps>();
</script>
